import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {injectIntl} from 'react-intl';
import { OutboundLink } from 'react-ga';

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import arrowicon from '../../../assets/images/icons/arrow.svg'
import whitearrowicon from '../../../assets/images/icons/whitearrow.svg'

const OutlineOverride = css`
  color: #3475B7;
  background-color: #fff;

  &:hover,
  &:focus {
    background-color: #3475B7;
    border-color: #3475B7;
  }
`

const ArrowOverride = css`
  p {
    background: url(${arrowicon});
    background-position: right;
    background-repeat: no-repeat;
    padding: 0px;
    padding-right: 20px;
  }

  &:hover,
  &:focus {
    p {
      background-image: url(${whitearrowicon});
    }
  }
`

const RedButton = css`
  background-color: #C8122F;
  border-color: #C8122F;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #9A1127;
    border-color: #9A1127;
    color: #fff;
    box-shadow: 0 0 0 3px rgba(200,18,47,0.5);
  }
`

const WhiteButton = css`
  background-color: #FFF;
  border-color: #C8122F;
  color: #AC031D;
  font-size: 1.25rem;
  &:hover,
  &:focus {
    background-color: #9A1127;
    border-color: #9A1127;
    color: #fff;
    box-shadow: 0 0 0 3px rgba(200,18,47,0.5);
  }
  @media screen and (max-width: 700px) {
    font-size: 1rem;
    bottom: 10px !important;
    padding:  5px 15px !important;
  }
`

const PetsButton = css`
  background-color: #292b8f;
  border-color: #292b8f;

  &:hover,
  &:focus {
    background-color: #141677;
    border-color: #141677;
    text-decoration: underline;
    box-shadow: none;
  }
`

const PetsOutlineButton = css`
  background-color: #fff;
  color: #292b8f;
  border: 2px solid #292b8f;
  padding: 15px 50px;

  &:hover,
  &:focus {
    background-color: #292b8f;
    color: #fff;
    border-color: #292b8f;
    box-shadow: none;
    text-decoration: underline;
  }
`

const DrawerButton = css`
  background-color: #E6BC3B;
  border-color: #E6BC3B;
  color: black;
  white-space: no-wrap !important;
  &:hover,
  &:focus {
    background-color: #7d6212;
    border-color: #7d6212;
    box-shadow: 0 0 0 3px #ad9753;
  }
`

const ChineseNYButton = css`
  background: rgb(250,225,175);
  background: linear-gradient(60deg, rgba(248,221,171,1) 0%, rgba(215,182,118,1) 50%, rgba(242,241,180,1) 100%);
  border-color: transparent;
  color: #ca0a0a;
  &:hover,
  &:focus {
    background-color: #7d6212;
    border-color: #7d6212;
    box-shadow: 0 0 0 3px #ad9753;
    color: #ca0a0a;
  }
`

const LargerButton = css`
  ${tw`text-lg`}
  padding: 20px 40px;
`

const DefaultStyle = props => css`
  ${tw`outline-none font-montserrat text-sm text-center no-underline cursor-pointer`}
  padding: 15px 30px;
  white-space: nowrap !important;
  border: 1px solid #3475B7;
  background-color: ${props.outline ? '#fff' : '#3475B7'};
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  line-height: 1.258;
  width: ${props.width ? props.width : 'auto'};
  color: ${props.outline ? '#3475B7' : '#fff'};
  border-radius: 9999px;
  display: inline-block;
  font-weight: 600;

  p {
    margin: 0px;
  }

  &:hover,
  &:focus {
    background-color: ${props.outline ? '#3475B7' : '#6db3f8'};
    border-color: ${props.outline ? '#3475B7' : '#6db3f8'};
    color: #fff;
    ${tw`shadow-outline`}
  }

  ${props.modification.includes('outline') ? OutlineOverride : null}
  ${props.modification.includes('arrow') ? ArrowOverride : null}
  ${props.modification.includes('red') ? RedButton : null}
  ${props.modification.includes('white') ? WhiteButton : null}
  ${props.modification.includes('pets') ? PetsButton : null}
  ${props.modification.includes('petsoutline') ? PetsOutlineButton : null}
  ${props.modification.includes('larger') ? LargerButton : null}
  ${props.modification.includes('drawer') ? DrawerButton : null}
  ${props.modification.includes('cny2021') ? ChineseNYButton : null}
`

const InternalLinkStyle = styled(Link)`
  ${DefaultStyle}
`

const LinkStyle = styled(OutboundLink)`
  ${DefaultStyle}
`

const ButtonStyle = styled.button`
  ${DefaultStyle}
`

const Button = (props) => {
  const {
    to,
    children,
    html,
    convert,
    href,
    intl,
    anchor,
    // eslint-disable-next-line
    modification
  } = props;
  let external = /(http(s?)):\/\//gi.test(to);
  if (href) {
    external = /(http(s?)):\/\//gi.test(href);
  }
  let ButtonEle;
  const alteredProps = Object.assign({}, props)

  if (to) {
    if (external) {
      ButtonEle = LinkStyle
      alteredProps.target = '_blank'
      alteredProps.rel = 'noopener noreferrer'
      alteredProps.eventLabel = alteredProps.label || alteredProps.to
    } else {
      ButtonEle = InternalLinkStyle
      if (convert) {
        alteredProps.to = fixInternalLinks(to, intl.locale)
      } else {
        alteredProps.to = to
      }
      if (anchor) {
        alteredProps.to += anchor
      }
    }
  } else {
    ButtonEle = ButtonStyle
  }

  if (!to && href) {
    ButtonEle = LinkStyle
    alteredProps.eventLabel = alteredProps.label || alteredProps.to
    alteredProps.to = href
  }

  if (html) {
    return <ButtonEle aria-label="button" {...alteredProps} dangerouslySetInnerHTML={{__html:html}} />
  }

  return <ButtonEle aria-label="button" {...alteredProps}>{children}</ButtonEle>
}

Button.propTypes = {
  // Either blue or outlined
  outline: PropTypes.bool,
  // Link
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  // HTML to render
  html: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  anchor: PropTypes.string,
  convert: PropTypes.bool,
  modification: PropTypes.string
}

Button.defaultProps = {
  outline: false,
  children: null,
  to: null,
  html: '',
  convert: true,
  anchor: '',
  modification: ''
}

export default injectIntl(Button)
